(function() {
	'use strict';
	angular.module('eventvods')
		 .factory( 'SettingsService', [ '$http', 'API_BASE_URL', '$q', function( $http, API, $q ) {
            return {
				validateName: function(name){
					var q = $q.defer();
					$http.get(API+'/validate/displayName/'+name)
					.then(function(){
						q.resolve();
					}, function(){
						q.reject();
					});
					return q.promise;
				},
				setName: function(name){
					return $http.post( API + '/user/displayName', {
						Name: name
					});
				},
				setSettings: function(settings){
					return $http.post( API + '/user/settings', settings);
				},
				changePassword: function(current, password, confirm){
					return $http.post( API + '/user/password', {
						current_pw: current,
						password: password,
						password_confirm: confirm
					});
				},
				verifyEmail: function(){
					return $http.post( API + '/user/sendEmail');
				}
            };
        } ] )
		.controller('SettingsController', ['SettingsService','SessionManager', '$q', '$rootScope', '$location', '$timeout', '$routeParams', '$window', 
		function(SettingsService, SessionManager, $q, $rootScope, $location, $timeout, $routeParams, $window) {
			var vm = this;
			vm.loading = true;
			vm.password = {};
			$rootScope.oldGame = $rootScope.game;
			$rootScope.game = 'static';
			vm.tab = parseInt($routeParams.tab || 1);
			function getSession(){
				vm.data = SessionManager.get();
				if(vm.data === false) $location.path('/');
				else if(vm.data === null) $timeout(getSession, 1000);
				else {
					$('[data-toggle="tooltip"]').tooltip();
					vm.loading = false;
					$window.prerenderReady = true;
				}
			}
			getSession();

			vm.validateName = function(name){
				var deferred = $q.defer();
				if(name == vm.data.displayName) deferred.resolve(false);
				else if(name.trim() === "") deferred.resolve("Please enter a display name.");
				else if(name.length > 30) deferred.resolve("30 Characters maximum.");
				else SettingsService.validateName(name)
					.then(function(){
						deferred.resolve(true);
					})
					.catch(function(){
						deferred.resolve("That name is already taken, sorry!");
					});
				return deferred.promise;
			};
			vm.updateName = function(){
				var deferred = $q.defer();
				SettingsService.setName(vm.data.displayName)
					.then(function(){
						deferred.resolve(true);
						$rootScope.$emit('triggerSessionUpdate');
					})
					.catch(function(err){
						deferred.resolve("Something went wrong! Please try again.");
					});
				return deferred.promise;
			};

			vm.saveSettings = function(){
				vm.settingsNotif = false;
				SettingsService.setSettings(vm.data.settings)
					.then(function(){
						$rootScope.$emit('triggerSessionUpdate');
						vm.settingsNotif = "Settings saved.";
					})
					.catch(function(){
						vm.settingsNotif = "An error occurred. If this persists, please let us know via the contact form.";
					});
			}
			vm.changePassword = function(form){
				vm.passwordNotif = false;
				$('#passwordChange input').addClass('ng-touched');
				if(form.$valid)
					SettingsService.changePassword(vm.password.current_pw, vm.password.password, vm.password.password_confirm)
					.then(function(){
						vm.passwordNotif = "Password updated! Logging you out...";
						$timeout(function(){
							$rootScope.$emit('triggerSessionUpdate');
							$location.path('/');
						}, 3000);

					})
					.catch(function(err){
						if(err.status == 403) vm.passwordNotif = "Incorrect current password. Please try again.";
						else vm.passwordNotif = "New password was insecure or confirmation didn't match."
					});
			}
			vm.changeEmail = function(){
				if(valid)
					SettingsService.changeEmail(vm.emailData.email, vm.emailData.confirm_pw)
					.then(function(){
						$rootScope.$emit('triggerSessionUpdate');
						$location.path('/login');
					}, function(response){
						switch(response.status){
							case 400:
								$('#new_email').val('').removeClass('valid').addClass('invalid').focus();
							break;
							case 403:
								$('#new_email_pw + label').attr('data-error', "Incorrect password. Please try again.");
								$('#new_email_pw').val('').removeClass('valid').addClass('invalid').focus();
							break;
							default:
								$('#new_email_pw + label').attr('data-error', "Unknown server error occured. Please try again.");
								$('#new_email_pw').val('').removeClass('valid').addClass('invalid').focus();
							break;
						}
					})
			}
			vm.verifyEmail = function(){
				SettingsService.verifyEmail()
					.then(function(){
						vm.data.emailConfirmation.sent = true;
					})
			}
		}]);
}());
